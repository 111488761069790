<template>
  <div
    class="mask"
    v-if="showCart && calculations.total > 0"
    @click="handleCartShowChange"
  />
  <div class="cart">
    <div class="product" v-if="showCart && calculations.total > 0">
      <div class="product__header">
        <div
          class="product__header__all"
          @click="() => setCartItemsChecked(calculations.allChecked)"
        >
          <span
            class="product__header__icon iconfont"
            v-html="calculations.allChecked ? '&#xe6e6;' : '&#xe6e7;'"
          >
          </span>
          全选
        </div>
        <div class="product__header__clear">
          <span
            class="product__header__clear__btn"
            @click="() => cleanCartProducts()"
            >清空购物车</span
          >
        </div>
      </div>
      <div v-for="item in productList" :key="item.id">
        <div
          v-if="item.status === '销售中' && item.residue > 0"
          class="product__item"
        >
          <div
            class="product__item__checked iconfont"
            v-html="item.check ? '&#xe6e6;' : '&#xe6e7;'"
            @click="() => changeCartItemChecked(item.id)"
          />
          <img class="product__item__img" :src="item.goodsImg" />
          <div class="product__item__detail">
            <h4 class="product__item__title">
              {{ item.goodsName }}
            </h4>
            <p class="product__item__residue">库存: {{ item.residue }} 件</p>
            <p class="product__item__price">
              <span class="product__item__yen">&yen;</span
              >{{ item.unitPrice.toFixed(2) }}
              <span class="product__item__origin"
                >&yen;{{ item.costPrice.toFixed(2) }}</span
              >
            </p>
          </div>
          <div class="product__number">
            <span
              class="product__number__minus"
              @click="
                () => {
                  changeCartItemInfo(item.id, item, -1)
                }
              "
              >-</span
            >
            {{ item.count || 0 }}
            <span
              :class="{
                product__number__plus: true,
                'product__number__plus--grayscale':
                  isLimit(item.id) || item.residue <= 0 || item.maxBuyNum == 0
              }"
              @click="
                () => {
                  if (
                    !isLimit(item.id) &&
                    item.residue > 0 &&
                    item.maxBuyNum > 0
                  ) {
                    changeCartItemInfo(item.id, item, 1)
                  } else {
                    Notify.danger('上限啦，不能再加了...')
                  }
                }
              "
              >+</span
            >
          </div>
        </div>
        <div
          v-if="
            item.status !== '销售中' || item.residue <= 0 || item.maxBuyNum <= 0
          "
          class="product__item"
        >
          <div
            class="product__item__checked iconfont gray"
            v-html="'&#xe6e7;'"
          />
          <img
            class="product__item__img product__item__img--grayscale"
            :src="item.goodsImg"
          />
          <div class="product__item__detail">
            <h4 class="product__item__title gray">
              {{ item.goodsName }}
              <span v-if="item.status !== '销售中'">{{
                ' (' + item.status + ')'
              }}</span>
            </h4>
            <p class="product__item__residue">库存: {{ item.residue }} 件</p>
            <p class="product__item__price gray">
              <span class="product__item__yen">&yen;</span
              >{{ item.unitPrice.toFixed(2) }}
              <span class="product__item__origin"
                >&yen;{{ item.costPrice.toFixed(2) }}</span
              >
            </p>
          </div>
          <div class="product__number">
            <span
              class="product__remove"
              @click="changeCartItemInfo(item.id, item, 0)"
              >移除商品</span
            >
            <!-- <span class="product__number__minus">-</span>
            {{ item.count || 0 }}
            <span class="product__number__plus product__number__plus--grayscale"
              >+</span
            > -->
          </div>
        </div>
      </div>
    </div>
    <div class="check">
      <div class="check__icon">
        <img
          src="/images/basket.png"
          class="check__icon__img"
          @click="handleCartShowChange"
        />
        <div class="check__icon__tag">{{ calculations.total }}</div>
      </div>
      <div class="check__info">
        总计：<span class="check__info__price"
          >&yen; {{ calculations.price }}</span
        >
      </div>
      <div class="check__btn" v-show="calculations.checked > 0">
        <router-link :to="{ path: `/orderConfirmation/` }">
          去结算
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useCommonCartEffect } from '@/effects/cartEffects'
import { Notify } from '@nutui/nutui'

// 获取购物车信息逻辑
const store = useStore()
const { productList, calculations, changeCartItemInfo, isLimit } =
  useCommonCartEffect()

const changeCartItemChecked = (productId) => {
  store.commit('cart/changeCartItemChecked', { productId })
}
// 清空购物车逻辑
const cleanCartProducts = () => {
  const userId = store.state.user.userInfo.id
  store.dispatch('cart/cleanCartProducts', { userId })
}

const setCartItemsChecked = (checked) => {
  store.commit('cart/setCartItemsChecked', !checked)
}

// 展示隐藏购物车逻辑
const showCart = ref(false)
const handleCartShowChange = () => {
  showCart.value = !showCart.value
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
.mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.cart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: $bgColor;
}
.product {
  overflow-y: scroll;
  max-height: 3rem;
  flex: 1;
  background: $bgColor;
  &__header {
    display: flex;
    line-height: 0.52rem;
    border-bottom: 1px solid $content-bgColor;
    font-size: 0.14rem;
    color: $content-fontcolor;
    &__all {
      width: 0.64rem;
      margin-left: 0.18rem;
    }
    &__icon {
      display: inline-block;
      margin-right: 0.1rem;
      vertical-align: top;
      color: $btn-bgColor;
      font-size: 0.2rem;
    }
    &__clear {
      flex: 1;
      margin-right: 0.16rem;
      text-align: right;
      &__btn {
        display: inline-block;
      }
    }
  }
  &__item {
    position: relative;
    display: flex;
    padding: 0.12rem 0;
    margin: 0 0.16rem;
    border-bottom: 0.01rem solid $content-bgColor;
    &__checked {
      line-height: 0.5rem;
      margin-right: 0.2rem;
      color: $btn-bgColor;
      font-size: 0.2rem;
    }
    &__detail {
      overflow: hidden;
    }
    &__img {
      width: 0.46rem;
      height: 0.46rem;
      margin-right: 0.16rem;
      &--grayscale {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
    }
    &__residue {
      margin: 0.06rem 0;
      font-size: 0.12rem;
      color: $content-fontcolor;
    }
    &__price {
      margin: 0.06rem 0 0 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
    }
    &__yen {
      font-size: 0.12rem;
    }
    &__origin {
      margin-left: 0.06rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      color: $light-fontColor;
      text-decoration: line-through;
    }
    .product__number {
      position: absolute;
      right: 0;
      bottom: 0.26rem;
      &__minus,
      &__plus {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
        line-height: 0.16rem;
        border-radius: 50%;
        font-size: 0.2rem;
        text-align: center;
      }
      &__minus {
        border: 0.01rem solid $medium-fontColor;
        color: $medium-fontColor;
        margin-right: 0.05rem;
      }
      &__plus {
        background: $btn-bgColor;
        color: $bgColor;
        margin-left: 0.05rem;
        line-height: 0.19rem;
        &--grayscale {
          background: $content-notice-fontcolor;
        }
      }
    }
  }
  &__remove {
    color: $medium-fontColor;
    font-size: 0.13rem;
  }
}
.check {
  display: flex;
  height: 0.49rem;
  border-top: 0.01rem solid $content-bgColor;
  line-height: 0.49rem;
  &__icon {
    position: relative;
    width: 0.84rem;
    &__img {
      display: block;
      margin: 0.12rem auto;
      width: 0.28rem;
      height: 0.26rem;
    }
    &__tag {
      position: absolute;
      left: 0.46rem;
      top: 0.04rem;
      padding: 0 0.04rem;
      min-width: 0.2rem;
      height: 0.2rem;
      line-height: 0.2rem;
      background-color: $hightlight-fontColor;
      border-radius: 0.1rem;
      font-size: 0.12rem;
      text-align: center;
      color: #fff;
      transform: scale(0.5);
      transform-origin: left center;
    }
  }
  &__info {
    flex: 1;
    color: $content-fontcolor;
    font-size: 0.12rem;
    &__price {
      line-height: 0.49rem;
      color: $hightlight-fontColor;
      font-size: 0.18rem;
    }
  }
  &__btn {
    width: 0.98rem;
    background-color: #4fb0f9;
    text-align: center;
    font-size: 0.14rem;
    a {
      color: $bgColor;
      text-decoration: none;
    }
  }
}
.gray {
  color: $medium-fontColor;
}
</style>
