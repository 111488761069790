<template>
  <div class="search">
    <div class="search__back iconfont" @click="handleBackClick">&#xe697;</div>
    <div class="search__content">
      <span class="search__content__icon iconfont" @click="handleSearchClick">
        &#xe741;
      </span>
      <input
        class="search__content__input"
        placeholder="请输入商品名称"
        v-model="currentSearchText"
        @keyup.enter="handleSearchClick"
      />
    </div>
  </div>
  <div class="content">
    <div class="category" v-if="true">
      <div
        :class="{
          category__item: true,
          'category__item--active': currentTab == item.value
        }"
        v-for="item in typeList"
        :key="item.description"
        @click="() => handleTabClick(item.value)"
      >
        {{ item.description }}
      </div>
    </div>
    <div class="product" id="scroll">
      <nut-infiniteloading
        containerId="scroll"
        :use-window="false"
        :has-more="hasMore"
        :load-icon="loadingIcon"
        @load-more="loadMore"
      >
        <div class="product__item" v-for="item in list" :key="item._id">
          <img
            class="product__item__img"
            :src="item.goodsImg == null ? defaultPicURL : item.goodsImg"
            @click="goodsDetailClick(item.id)"
          />
          <div class="product__item__detail">
            <h4 class="product__item__title">{{ item.goodsName }}</h4>
            <p class="product__item__residue">库存: {{ item.residue }} 件</p>
            <p class="product__item__price">
              <span class="product__item__yen">&yen;</span
              >{{ item.unitPrice.toFixed(2) }}
              <span class="product__item__origin"
                >&yen;{{ item.costPrice.toFixed(2) }}</span
              >
            </p>
          </div>
          <div class="product__number">
            <span
              class="product__number__minus"
              @click="
                () => {
                  if (getProductCartCount(item.id) > 0) {
                    changeCartItemInfo(item.id, item, -1)
                  }
                }
              "
              >-</span
            >
            {{ getProductCartCount(item.id) }}
            <span
              :class="{
                product__number__plus: true,
                'product__number__plus--active':
                  isLimit(item.id) || item.residue <= 0 || item.maxBuyNum == 0
              }"
              @click="
                () => {
                  if (
                    !isLimit(item.id) &&
                    item.residue > 0 &&
                    item.maxBuyNum > 0
                  ) {
                    changeCartItemInfo(item.id, item, 1)
                  } else {
                    Notify.danger('上限啦，不能再加了...')
                  }
                }
              "
              >+</span
            >
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
  <Toast v-if="show" :message="toastMessage" />
</template>

<script setup>
import { reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useCommonCartEffect } from '@/effects/cartEffects'
import { reqGetGoodsList, reqGetGoodsType } from '@/api'
import { useToastEffect } from '@/components/Toast'
import { PAGESIZE } from '@/constant'
import { Notify } from '@nutui/nutui'

const router = useRouter()
const route = useRoute()
const typeList = ref([])
const isClear = ref(false)
const loadingIcon = ref('/images/loading.png')
const currentTab = ref(route.query.type ? route.query.type : '1')
const currentSearchText = ref(
  route.query.goodsName ? route.query.goodsName : ''
)
const defaultPicURL = 'images/defaultPic.png'
const { show, toastMessage } = useToastEffect()

// 获取商品类型
const getGoodsType = async () => {
  const result = await reqGetGoodsType()
  typeList.value.push({
    description: '全部',
    value: '1'
  })
  for (const iterator of result.list) {
    typeList.value.push(iterator)
  }
}
getGoodsType()

// Tab 切换相关的逻辑
const handleTabClick = (tab) => {
  if (tab) {
    currentTab.value = tab
  } else currentTab.value = '1'
  content.list.splice(0)
  pageNum = 1
  hasMore.value = true
  isClear.value = false
  getContentData(isClear.value)
}

// 列表内容相关的逻辑
const hasMore = ref(true)
let pageNum = 1
const totalPage = ref(0)
const content = reactive({ list: [] })
const getContentData = async (isClear) => {
  const result = await reqGetGoodsList({
    goodsType: currentTab.value,
    goodsName: currentSearchText.value,
    goodsStatus: 1,
    // TODO: 排序 分页
    page: pageNum,
    pageSize: PAGESIZE,
    orderBy: 'goods_Name',
    sort: 'desc'
  })
  if (isClear.value) {
    // 解决search连续触发返回多个重复值
    content.list.splice(0)
  }

  totalPage.value = result.totalPages
  for (const iterator of result.list) {
    content.list.push(iterator)
  }
  // 只返回一页时能正确显示
  if (totalPage.value === 1) {
    hasMore.value = false
  }
}
const { list } = toRefs(content)
function switchTab() {
  handleTabClick(route.query.type)
}
switchTab()

// 分页加载处理
const loadMore = (done) => {
  pageNum++
  setTimeout(() => {
    isClear.value = false
    getContentData(isClear.value)
    if (pageNum >= totalPage.value) {
      hasMore.value = false
    }
    done()
  }, 500)
}

// 购物车-加载
const loadShopCart = () => {
  const store = useStore()
  store.dispatch('cart/loadCartFromServer', {
    userId: store.getters.userInfo.id
  })
}
loadShopCart()

// 购物车-修改
const { cartList, changeCartItemInfo, isLimit } = useCommonCartEffect()

const getProductCartCount = (productId) => {
  return cartList?.productList?.[productId]?.count || 0
}

// 搜索
const handleSearchClick = () => {
  content.list.splice(0)
  isClear.value = true
  getContentData(isClear)
}

// 返回
const handleBackClick = () => {
  router.push('/')
}

// 获取商品详情
const goodsDetailClick = (id) => {
  router.push(`/shop/goodsDetail/${id}`)
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/customizedNutui.scss';
.search {
  display: flex;
  margin: 0.14rem 0 0.04rem 0;
  line-height: 0.32rem;
  &__back {
    width: 0.3rem;
    font-size: 0.24rem;
    color: #b6b6b6;
  }
  &__content {
    display: flex;
    flex: 1;
    background: $search-bgColor;
    border-radius: 0.16rem;
    &__icon {
      width: 0.44rem;
      text-align: center;
      color: $search-fontColor;
    }
    &__input {
      display: block;
      width: 100%;
      padding-right: 0.2rem;
      border: none;
      outline: none;
      background: none;
      height: 0.32rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
  }
}
.content {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0.6rem;
  bottom: 0.5rem;
}
.category {
  overflow-y: scroll;
  height: 100%;
  width: 0.76rem;
  background: $search-bgColor;
  &__item {
    line-height: 0.4rem;
    text-align: center;
    font-size: 14px;
    color: #333;
    &--active {
      background: $bgColor;
    }
  }
}
.product {
  overflow-y: scroll;
  flex: 1;
  &__item {
    position: relative;
    display: flex;
    padding: 0.12rem 0;
    margin: 0 0.16rem;
    border-bottom: 0.01rem solid $content-bgColor;
    &__detail {
      overflow: hidden;
    }
    &__img {
      width: 0.68rem;
      height: 0.68rem;
      margin-right: 0.16rem;
    }
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
    }
    &__residue {
      margin: 0.06rem 0;
      font-size: 0.12rem;
      color: $content-fontcolor;
    }
    &__price {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
    }
    &__yen {
      font-size: 0.12rem;
    }
    &__origin {
      margin-left: 0.06rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      color: $light-fontColor;
      text-decoration: line-through;
    }
    .product__number {
      position: absolute;
      right: 0;
      bottom: 0.12rem;
      &__minus,
      &__plus {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
        line-height: 0.16rem;
        border-radius: 50%;
        font-size: 0.2rem;
        text-align: center;
      }
      &__minus {
        border: 0.01rem solid $medium-fontColor;
        color: $medium-fontColor;
        margin-right: 0.05rem;
      }
      &__plus {
        background: $btn-bgColor;
        color: $bgColor;
        margin-left: 0.05rem;
        &--active {
          background: $content-notice-fontcolor;
        }
      }
    }
  }
}
</style>
